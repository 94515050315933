<template>
  <div class="applica">
    <div class="appMain">
      <div class="topBack">
        <span
          class="iconfont icon-dingbulan_return"
          style="color: #252631"
          @click="Back()"
          >{{ $t("PC.Product.AISHOPScene.ca5a01") }}</span
        >
      </div>
      <div class="appContent">
        <div class="title">
          <img :src="imgServeUrl + details.url" alt="" />
          <div class="titleContent">
            <p>{{ details.name }}</p>
            <p>
              <span
                v-for="(item, index) in details.labels.split(',')"
                :key="index"
                >{{ item }}</span
              >
            </p>
            <p>{{ $t("PC.Product.AISHOPScene.f49c3f") }}</p>
            <p>
              {{ $t("PC.Product.AISHOPScene.ba8dc1")
              }}{{ details.categories.replace(/,/g, "、") }}
            </p>
          </div>
        </div>
        <div class="detaolsTitle">
          <span class="colorBlock"></span>
          <span>{{ $t("PC.Product.AISHOPScene.163df0") }}</span>
        </div>
        <p class="introduct">
          {{ details.brief }}
        </p>
        <p class="recommend">
          {{ $t("PC.Product.AISHOPScene.b59704") }}
          <span class="deg">
            <span class="iconfont iconstar" v-for="index of deg" :key="index">
            </span>
            <span class="degA">
              <span
                class="iconfont iconstar"
                v-for="index of details.star"
                :key="index"
                style="color: #f8b929"
              ></span>
            </span>
          </span>
        </p>

        <div class="detaolsTitle">
          <span class="colorBlock"></span>
          <span>{{ $t("PC.Product.AISHOPScene.09b77d") }}</span>
        </div>
        <!-- 应用场景 -->
        <div class="target-tracking">
          <div class="function-demonstration">
            <video controls="controls" width="960px" autoplay="autoplay">
              <source
                :src="
                  videoUrl == undefined
                    ? require('@/assets/images/video/posture.mp4')
                    : videoUrl
                "
                type="video/webm"
              />
              {{ $t("PC.Product.AISHOPScene.novide") }}
            </video>
          </div>
        </div>

        <div class="detaolsTitle">
          <span class="colorBlock"></span>
          <span>{{ $t("PC.Product.AISHOPScene.326e56") }}</span>
        </div>

        <p
          v-for="(value, index) in sceneApplicatList"
          :key="index"
          class="scene"
        >
          <span v-show="$t('locale') == 'zh'">{{ value.name }}：</span>
          <span v-show="$t('locale') == 'zh'">{{ value.description }}</span>
          <span v-show="$t('locale') == 'en'">{{ value.name_en }}：</span>
          <span v-show="$t('locale') == 'en'">{{ value.description_en }}</span>
        </p>
      </div>
    </div>
    <FooterC></FooterC>
  </div>
</template>

<script>
import FooterC from "../../components/FooterC";
export default {
  components: {
    FooterC,
  },
  data() {
    return {
      deg: 5,
      sceneApplicatList: [],
      imgServeUrl: Global.IMG_SERVE,
      videoUrl: undefined,
    };
  },
  created() {
    this.getDetail();
    this.getsceneapplication();
  },
  methods: {
    getDetail() {
      let detail = JSON.parse(sessionStorage.getItem("details"));
      this.scene_id = detail.id;
      if (this.$t("locale") == "zh") {
        this.details = {
          name: detail.scene_name,
          labels: detail.labels,
          categories: detail.categories,
          brief: detail.brief_introduction,
          url: detail.url,
          star: detail.recommend_index,
        };
      } else {
        this.details = {
          name: detail.scene_name_en,
          labels: detail.labels_en,
          categories: detail.categories_en,
          brief: detail.brief_introduction_en,
          url: detail.url,
          star: detail.recommend_index,
        };
      }
    },
    Back() {
      this.$router.push({
        path: "/AIShopNew",
      });
    },

    getsceneapplication() {
      this.axios
        .get(
          Global.COOVALLY_URL +
            Global.GET_SCENE_APPLICAT_LIST +
            "?scene_id=" +
            `${this.scene_id}`
        )
        .then((res) => {
          // console.log(res);
          if (res.status == 200 || res.status == 201) {
            this.sceneApplicatList = res.data.results;
          } else {
            this.$message({
              message: "请求出错",
              type: "error",
            });
          }
        })
        .catch((error) => {
          return error;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-main {
  width: 100%;
  padding: 0;
  height: 100%;
  overflow-y: scroll; //y轴滚动条始终存在，防止画面抖动
  overflow-x: hidden;
}
.appMain {
  width: 960px;
  margin: 0 auto 90px;
  padding-top: 40px;
  .topBack {
    cursor: pointer;
    margin: 32px 0 12px;

    span {
      font-size: 14px;
      color: #396dd6;
      box-sizing: border-box;
      padding: 10px;
      padding-left: 0;

      &::before {
        margin-right: 4px;
        display: inline-block;
        font-size: 10px;
        transform: scale(0.8);
        transform-origin: center center;
      }
    }
  }

  .appContent {
    height: 100%;
    font-size: 14px;
    color: #666666;

    .introduct {
      line-height: 22px;
    }

    .scene {
      display: flex;
      text-align: left;
      margin-bottom: 10px;

      span {
        display: inline-block;
      }

      span:nth-of-type(1) {
        color: #333;
        white-space: nowrap;
      }

      span:nth-of-type(2) {
        color: #666;
      }
    }

    .recommend {
      color: #333333;
      margin-top: 20px;

      .deg {
        position: relative;

        .iconstar {
          font-size: 12px;
          margin-right: 6px;
          color: #eff2f6;
        }

        .degA {
          position: absolute;
          left: 0;
          z-index: 2;

          .iconstar-active {
            font-size: 12px;
            margin-right: 6px;
            margin-right: 6px;
            color: #f8b929;
          }
        }
      }
    }

    .title {
      display: flex;

      img {
        height: 180px;
      }

      .titleContent {
        margin-left: 30px;

        p {
          color: #666666;
          margin: 20px 0;
        }

        p:nth-of-type(1) {
          margin: 15px 0 14px;
          font-size: 18px;
          color: #333333;
        }

        p:nth-of-type(2) {
          margin: 0;

          span {
            margin-right: 12px;
            display: inline-block;
            padding: 8px 11px;
            background: #f5fcfe;
            border-radius: 4px;
            color: #4aa3ff;
            border: 1px solid #e8f7fb;
          }
        }

        p:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .detaolsTitle {
      display: flex;
      margin: 40px 0 10px;

      span {
        font-size: 18px;
        font-weight: 600;
        color: #333333;
      }

      .colorBlock {
        margin-right: 10px;
        align-self: center;
        width: 4px;
        height: 18px;
        background: #396dd6;
      }
    }
  }
  .target-tracking {
    .function-demonstration {
      padding-top: 10px;
      width: auto;
      height: auto;
    }

    .optionBox {
      display: flex;
      justify-content: start;
      align-items: center;

      // 改写input默认样式
      .a-upload {
        padding: 4px 10px;
        margin: 10px 0;
        width: auto;
        min-width: 86px;
        height: 30px;
        line-height: 20px;
        position: relative;
        cursor: pointer;
        color: #fff;
        background-color: #396dd6;
        font-size: 12px;
        border-radius: 4px;
        overflow: hidden;
        display: inline-block;

        span {
          background-color: transparent;
          width: auto;
          white-space: nowrap;
          padding: 0 10px;
          box-sizing: border-box;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        input {
          padding: 30px;
          z-index: 1;
          width: 100%;
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          background-color: transparent;
          opacity: 0;
          filter: alpha(opacity=0);
          cursor: pointer !important;

          &:hover {
            cursor: wait;
          }
        }

        &:hover {
          background-color: rgba(57, 109, 214, 0.8);
          text-decoration: none;
        }
      }
    }

    .upload-tips {
      font-size: 12px;
      font-weight: 400;
      color: #aaaaaa;
      line-height: 17px;
    }
  }
  .footer {
    flex: 0;
  }
}
</style>
